import React, { useEffect, useState, useContext, useRef } from "react";
import {
  checkValue,
  isSavedUserInfo,
  sendEventsCollectEmail,
} from "../../../helpers";
import { targetStatus } from "../../../helpers/browser";
import { PAGE_OPTIONS, PAGES } from "../../../helpers/constants";
import PropTypes from "prop-types";
import { LayoutContext } from "../../../store/context/layout-context";
import { MediaContext } from "../../../store/context/media-context";
import EmailCollectAnalyticsController from "../../../controllers/common/emailCollectAnalyticsController";

const EmailCollectContainer = (props) => {
  const {
    children,
    page,
    customLocalSave,
    onSuccess,
    pageAuthState,
    updatePageAuthState,
    redirect = true,
    customRedirectUrl,
    operation = false,
    customType,
    language = "en",
    customLabel,
    extAction = false,
    extFunc,
  } = props;

  const [getFormState, setFormState] = useState({
    email: "",
    isLoading: false,
    error: "",
    opacity: 0,
  });

  const context = useContext(LayoutContext);
  const { isTabletOrLess } = useContext(MediaContext);
  const countRef = useRef(context);
  countRef.current = context;
  const analytics = new EmailCollectAnalyticsController(customLabel, page);

  useEffect(() => {
    countRef.current.updatePageFormState(
      customLocalSave
        ? !!localStorage.getItem(customLocalSave[0])
        : isSavedUserInfo()
    );
    updateFormState("opacity", 1);
  }, [context]);

  useEffect(() => {
    updatePageAuthState &&
      updatePageAuthState(
        customLocalSave
          ? !!localStorage.getItem(customLocalSave[0])
          : isSavedUserInfo()
      );
  }, []);

  const urlRedirect =
    typeof PAGE_OPTIONS[PAGES[[page]]].REDIRECTED_URL === "object"
      ? PAGE_OPTIONS[PAGES[[page]]].REDIRECTED_URL[
          isTabletOrLess ? "MOBILE" : "DESKTOP"
        ]
      : PAGE_OPTIONS[PAGES[[page]]].REDIRECTED_URL;

  const updateFormState = (name, value) => {
    setFormState((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleEmailChange = (e) => {
    updateFormState("email", e.target.value);
  };

  const defaultSave = (email) => {
    localStorage.setItem("emailCollect", "true");
    localStorage.setItem("collectData", email);
  };

  const handleEmailSubmit = async (e) => {
    const { captcha, token, isInjected, injection } = countRef.current;
    if (e) {
      e.preventDefault();
    }
    if (!isInjected) {
      injection(true);
    }
    const errorResult = checkValue(
      "email",
      getFormState.email,
      false,
      language
    );
    updateFormState("error", errorResult);
    if (!errorResult) {
      if (
        typeof window !== "undefined" &&
        !window.location.href.includes("localhost") &&
        !token
      ) {
        if (!captcha.current) {
          updateFormState("isLoading", true);
          return setTimeout(() => {
            handleEmailSubmit();
          }, 500);
        } else {
          await captcha.current.executeAsync();
          updateFormState("isLoading", false);
        }
      }
      updateFormState("isLoading", true);
      const isOperation =
        operation || !!PAGE_OPTIONS[PAGES[page]].CLICK_FORM_OPERATION;
      const typeOrOperationValue = isOperation
        ? customType || PAGE_OPTIONS[PAGES[page]].CLICK_FORM_OPERATION
        : PAGE_OPTIONS[PAGES[page]].CLICK_FORM_TYPE;
      const software = isOperation
        ? PAGE_OPTIONS[PAGES[page]].SOFTWARE
        : undefined;
      const textPleaseTryAgain = () => {
        if (language === "cn") return "出现了一些错误。请重试。";
        if (language === "ja")
          return "エラーが発生していました。再試行してください。";
        if (language === "de") return "Etw. ging schief. Bitte erneuern Sie.";
        return "Something went wrong. Please try again.";
      };

      sendEventsCollectEmail(
        getFormState.email,
        typeOrOperationValue,
        isOperation,
        software
      )
        .then((response) => {
          if (response.ok) {
            customLocalSave
              ? localStorage.setItem(customLocalSave[0], customLocalSave[1])
              : defaultSave(getFormState.email);
            updateFormState("error", "");
            updateFormState("email", "");
            updatePageAuthState
              ? updatePageAuthState(true)
              : countRef.current.updatePageFormState(true);
            onSuccess && onSuccess();
            analytics.sendEmailSubmitSuccessEvent();
            redirect &&
              window.open(
                customRedirectUrl ? customRedirectUrl : urlRedirect,
                targetStatus() ? "_blank" : "_self"
              );
            extAction && extFunc();
          } else {
            throw new Error(response.statusText);
          }
        })
        .catch((err) => {
          analytics.sendEmailValidationEvent(err.message);
          updateFormState("error", textPleaseTryAgain());
          updateFormState("email", "");
        })
        .finally(() => updateFormState("isLoading", false));
    } else {
      analytics.sendEmailValidationEvent(getFormState.email.length);
      updateFormState("email", "");
    }
  };

  const handleKeyUp = (e) => {
    const { injection, isInjected } = countRef.current;

    if (e.keyCode === 13) {
      handleEmailSubmit();
    }
    if (e.target.value.length > 0 && !isInjected) {
      injection(true);
    }
  };
  return (
    <>
      {React.cloneElement(children, {
        isLoading: getFormState.isLoading,
        emailValue: getFormState.email,
        onEmailChange: handleEmailChange,
        onSubmit: handleEmailSubmit,
        onError: getFormState.error,
        onKey: handleKeyUp,
        formOpacity: getFormState.opacity,
        isAuth: pageAuthState ? pageAuthState : countRef.current.pageFormState,
        redirectUrl: customRedirectUrl ? customRedirectUrl : urlRedirect,
      })}
    </>
  );
};

EmailCollectContainer.propTypes = {
  children: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
  pageAuthState: PropTypes.bool,
  updatePageAuthState: PropTypes.func,
  redirect: PropTypes.bool,
  operation: PropTypes.bool,
  customRedirectUrl: PropTypes.string,
  customType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EmailCollectContainer;
