import React from "react";
import "./index.scss";
import Container from "../../common/Container";
import PointList from "../../common/UI/List/PointList";
import { INTRO_CONTENT } from "./index.content";
import { PAGE_OPTIONS, PAGES } from "../../../helpers/constants";
import LicenseEmailCollectContainer from "../../../containers/common/forms/LicenseEmailCollectContainer";
import { getTrueClasses } from "../../../helpers";
const { LIST_ITEMS, IMAGES } = INTRO_CONTENT;
const { desktop, tablet, mobile } = IMAGES;
import PropTypes from "prop-types";

const HomeIntro = ({
  isSecondStateCollectEmail,
  isLoading,
  setIsSecondStateCollectEmail,
}) => {
  return (
    <section className="PgPPI-Intro">
      <Container mediumSize modeClass="PgPPI-Intro-container">
        <div className="PgPPI-Intro-block">
          <div className="PgPPI-Intro-content">
            <p className="PgPPI-Intro__title">
              Discover New Fast-Growing Product Ideas for Your Business Line on
              Amazon
            </p>
            <PointList
              arrayOfItems={LIST_ITEMS}
              customClassName="PgPPI-Intro__list"
            />
            <p className="PgPPI-Intro__cta">
              Gain Access to Personal Product Ideas Now
            </p>
            <LicenseEmailCollectContainer
              pageLoading={isLoading}
              pageFormState={isLoading ? false : isSecondStateCollectEmail}
              updatePageFormState={setIsSecondStateCollectEmail}
              page={PAGES.PERSONAL_PRODUCT_IDEAS}
              customPlaceholder="Enter your email here"
              buttonTextFirstState="Go to Personal Ideas"
              buttonTextSecondState="GO TO PERSONAL IDEAS"
              customClass="PgPPI-Intro__collect"
              redirectUrl={
                PAGE_OPTIONS[PAGES.PERSONAL_PRODUCT_IDEAS].REDIRECTED_URL
              }
              isOpacity={false}
              setIsOpacity={() => {}}
              setIsPaid={() => {}}
              isCheckLicense={false}
            />
          </div>
          <div
            className={getTrueClasses(
              "PgPPI-Intro-image",
              isSecondStateCollectEmail && "PgPPI-Intro-image_up"
            )}
          >
            <picture>
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${mobile.webp} 1x, ${mobile.webp2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${mobile.png} 1x, ${mobile.png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 1199px)"
                srcSet={`${tablet.webp} 1x, ${tablet.webp2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 1199px)"
                srcSet={`${tablet.png} 1x, ${tablet.png2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 1200px)"
                srcSet={`${desktop.webp} 1x, ${desktop.webp2x} 2x`}
              />
              <source
                media="(min-width: 1200px)"
                srcSet={`${desktop.png} 1x, ${desktop.png2x} 2x`}
              />
              <img src={desktop.png} alt="" loading="lazy" />
            </picture>
          </div>
        </div>
      </Container>
    </section>
  );
};

HomeIntro.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSecondStateCollectEmail: PropTypes.bool.isRequired,
  setIsSecondStateCollectEmail: PropTypes.func.isRequired,
};

export default HomeIntro;
