import React, { useState } from "react";
import EmailCollectContainer from "./EmailCollectContainer";
import LicenseEmailCollect from "../../../components/common/Forms/LicenseEmailCollect";
import PropTypes from "prop-types";
import { handleCheckLicenseForm } from "../../../helpers/index";

const LicenseEmailCollectContainer = ({
  page,
  pageFormState,
  updatePageFormState,
  buttonTextFirstState,
  buttonTextSecondState,
  customClass,
  redirectUrl,
  isOpacity,
  setIsOpacity,
  setIsPaid,
  isCheckLicense,
  language = "en",
  customPlaceholder,
}) => {
  const [getRedirectShowText, setRedirectShowText] = useState(false);

  const handleSuccess = () => {
    setRedirectShowText(true);
    isCheckLicense && handleCheckLicenseForm(setIsPaid, setIsOpacity);
  };
  return (
    <EmailCollectContainer
      page={page}
      pageAuthState={pageFormState}
      updatePageAuthState={updatePageFormState}
      onSuccess={handleSuccess}
      customRedirectUrl={redirectUrl}
      language={language}
    >
      <LicenseEmailCollect
        page={page}
        buttonTextFirstState={buttonTextFirstState}
        buttonTextSecondState={buttonTextSecondState}
        isShowText={getRedirectShowText}
        customClass={customClass}
        isOpacity={isOpacity}
        language={language}
        customPlaceholder={customPlaceholder}
      />
    </EmailCollectContainer>
  );
};

LicenseEmailCollectContainer.propTypes = {
  page: PropTypes.string.isRequired,
  pageFormState: PropTypes.bool,
  updatePageFormState: PropTypes.func,
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string.isRequired,
  redirectUrl: PropTypes.string,
  isOpacity: PropTypes.bool.isRequired,
  setIsOpacity: PropTypes.func.isRequired,
  setIsPaid: PropTypes.func.isRequired,
  customPlaceholder: PropTypes.string,
};

export default LicenseEmailCollectContainer;
