import React from "react";
import PropTypes from "prop-types";
import EmailCollectContainer from "./EmailCollectContainer";
import FormIntroBlock from "../../../components/common/Forms/FormIntroBlock";
import FormPageBlock from "../../../components/common/Forms/FormPageBlock";

const FormBlockContainer = (props) => {
  const {
    pageFormState,
    updatePageFormState,
    isIntro,
    title,
    isLoading,
    buttonTextFirstState,
    buttonTextSecondState,
    page,
    placeholder,
    customClass,
    onSuccess,
    redirect,
    customLocalSave,
    customPagePlaceHolder,
    language = "en",
  } = props;

  return (
    <EmailCollectContainer
      page={page}
      redirect={redirect === undefined ? true : redirect}
      pageAuthState={isLoading ? false : pageFormState}
      updatePageAuthState={updatePageFormState}
      onSuccess={onSuccess}
      customLocalSave={customLocalSave}
      language={language}
    >
      {isIntro ? (
        <FormIntroBlock
          title={title}
          customClass={customClass}
          buttonTextFirstState={buttonTextFirstState}
          buttonTextSecondState={buttonTextSecondState}
          placeholder={placeholder}
          language={language}
        />
      ) : (
        <FormPageBlock
          title={title}
          buttonTextFirstState={buttonTextFirstState}
          buttonTextSecondState={buttonTextSecondState}
          customClass={customClass}
          customPlaceHolder={customPagePlaceHolder}
          language={language}
        />
      )}
    </EmailCollectContainer>
  );
};

FormBlockContainer.propTypes = {
  onFormSubmit: PropTypes.func,
  pageFormState: PropTypes.bool,
  updatePageFormState: PropTypes.func,
  onSuccess: PropTypes.func,
  isIntro: PropTypes.bool,
  isLoading: PropTypes.bool,
  redirect: PropTypes.bool,
  page: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  buttonTextFirstState: PropTypes.string.isRequired,
  buttonTextSecondState: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  customLocalSave: PropTypes.array,
  customPagePlaceHolder: PropTypes.string,
};

export default FormBlockContainer;
