import React, { useEffect, useState } from "react";
import LayoutDefault from "../layouts/LayoutDefault";
import OgImg from "../assets/images/raster/components/common/mainscreen.png";
import Seo from "../components/Seo";
import HomeIntro from "../components/personal-product-ideas/HomeIntro";
import ReportCta from "../components/personal-product-ideas/ReportCta";
import BigData from "../components/personal-product-ideas/BigData";
import { isSavedUserInfo } from "../helpers";
import AmzAi from "../components/personal-product-ideas/AmzAi";
import AmzCategories from "../components/personal-product-ideas/AmzCategories";
import Instruction from "../components/personal-product-ideas/Instruction";
import FormBlockContainer from "../containers/common/forms/FormBlockContainer";
import { PAGES } from "../helpers/constants";
import "../assets/scss/pages/personal-product-ideas/index.scss";

const PgPPI = () => {
  const [isSecondStateCollectEmail, setIsSecondStateCollectEmail] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsSecondStateCollectEmail(isSavedUserInfo());
    setIsLoading(false);
  }, []);

  return (
    <LayoutDefault>
      <Seo
        title="Get Personal Product Ideas to Sell on Amazon"
        description="Get new personal product ideas from selected subcategories on Amazon once/week. Our analyst will select products with high selling potential from any of the 19 popular categories. Discover new profitable items and develop your online business."
        page="personal-product-ideas"
        manifest="browserconfig-index.xml"
        ogImg={OgImg}
        keywords={["product ideas", "new product ideas"]}
      />
      <HomeIntro
        isSecondStateCollectEmail={isSecondStateCollectEmail}
        isLoading={isLoading}
        setIsSecondStateCollectEmail={setIsSecondStateCollectEmail}
      />
      <BigData />
      <AmzAi />
      <ReportCta />
      <AmzCategories />
      <Instruction />
      <FormBlockContainer
        title="Choose Your Amazon Subcategory and Get the Potential Product Every Week"
        customPagePlaceHolder="Enter your email here"
        isLoading={isLoading}
        pageFormState={isSecondStateCollectEmail}
        updatePageFormState={setIsSecondStateCollectEmail}
        buttonTextFirstState="Go to Personal Ideas"
        buttonTextSecondState="GO TO PERSONAL IDEAS"
        page={PAGES.PERSONAL_PRODUCT_IDEAS}
        customClass={`${
          isSecondStateCollectEmail
            ? "PgPPI-global__emailCollect_secondState"
            : ""
        } PgPPI-global__emailCollect`}
      />
    </LayoutDefault>
  );
};

export default PgPPI;
