import React from "react";
import Container from "../../common/Container";
import "./index.scss";
import PointList from "../../common/UI/List/PointList";
import { AMZ_CATEGORIES_CONTENT } from "./index.content";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const AmzCategories = () => {
  const isTablet = useBreakpoint().fullTabInterval;
  const { LISTS } = AMZ_CATEGORIES_CONTENT;
  const tabletLists = [
    [...LISTS[0], ...LISTS[1].slice(0, 4)],
    [...LISTS[1].slice(4), ...LISTS[2]],
  ];
  const currentList = isTablet ? tabletLists : LISTS;

  return (
    <section className="PgPPI-AmzCategories">
      <Container mediumSize modeClass="PgPPI-AmzCategories-container">
        <p className="PgPPI-AmzCategories__title">
          Our analysts study all data received from AI, and manually hunt for
          potential trends from 19 popular Amazon categories, such as:
        </p>
        <div className="PgPPI-AmzCategories-box">
          {currentList.map((categoryArray, index) => {
            return (
              <PointList
                key={index}
                arrayOfItems={categoryArray}
                customClassName="PgPPI-AmzCategories__list PgPPI-AmzCategories-list"
                customItemClassName="PgPPI-AmzCategories-list__item"
              />
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default AmzCategories;
